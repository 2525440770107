import phonesIcon from '../assets/categoryIcons/phones.png';
import computerIcon from '../assets/categoryIcons/computer.png';
import tvIcon from '../assets/categoryIcons/tv.png';
import headPhonesIcon from '../assets/categoryIcons/headPhones.png';
import watchesIcon from '../assets/categoryIcons/watches.png';
import clothingIcon from '../assets/categoryIcons/clothing1.png';
import {
  FREE_PLAN_COUNT_LIMIT,
  FREE_PLAN_PRICE_LIMIT,
  MEMBER_PLAN_COUNT_LIMIT,
  MEMBER_PLAN_PRICE_LIMIT,
  SUBSCRIPTION_MEMBER_PLAN,
} from './types';
import { transitions } from '../transactions/transactionProcessPurchase';
import { formatMoney } from './currency';
import { types as sdkTypes } from '../util/sdkLoader';
const { Money } = sdkTypes;

export function getFileExtension(filePath) {
  const extIndex = filePath.lastIndexOf('.');
  return extIndex > 0 ? filePath.substring(extIndex + 1) : '';
}

export const getUserDetails = user => {
  const fullName = user?.attributes?.profile?.publicData?.fullName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  const firstName =
    user?.attributes?.profile?.displayName && user?.attributes?.profile?.displayName.split(' ')[0];
  return {
    fullName,
    email,
    id,
    firstName,
  };
};

export const getLabel = (category, key) => {
  const label = category.find(c => c.option === key);
  return label ? label.label : key;
};

export const popularCategories = [
  {
    label: 'Phones & Accessories',
    icon: phonesIcon,
    search: '?refinementList=phones-accessories',
  },
  {
    label: 'Computers & Laptops',
    icon: computerIcon,
    search: '?refinementList=computers-laptops',
  },
  { label: 'Clothing', icon: clothingIcon, search: '?refinementList=clothing' },
  { label: 'TV & Video', icon: tvIcon, search: '?refinementList=tv-video' },
  { label: 'Watches', icon: watchesIcon, search: '?refinementList=watches' },
  {
    label: 'Headphones & MP3 Players',
    icon: headPhonesIcon,
    search: '?refinementList=headphones-mP3-players',
  },
];

export const popularSubCategories = [
  {
    label: 'Phones & Accessories',
    key: 'phones-accessories',
    search: '?pub_subCategoryChild=phones-accessories',
  },
  {
    label: 'Computers & Laptops',
    key: 'computers-laptops',
    search: '?pub_subCategoryChild=computers-laptops',
  },
  {
    label: 'Men’s Clothing',
    key: 'mens-clothing',
    search: '?pub_subCategory=mens-clothing',
  },
  {
    label: ' Women’s Clothing',
    key: 'womens-clothing',
    search: '?pub_subCategory=womens-clothing',
  },
  { label: 'TV & Video', key: 'tv-video', search: '?pub_subCategory=tv-video' },
  { label: 'Watches', key: 'watches', search: '?pub_subCategory=watches' },
  {
    label: 'Headphones & MP3 Players',
    key: 'headphones-mP3-players',
    search: '?pub_subCategory=headphones-mP3-players',
  },
];

export const extractSoldStats = response => {

  const includedData = response.data.included;
  const listingsSoldCount =
    includedData[0]?.attributes?.profile?.publicData?.listingsSoldCount || 0;
  const listingsSoldPrice =
    includedData[0]?.attributes?.profile?.publicData?.listingsSoldPrice || 0;
  return { listingsSoldCount, listingsSoldPrice };
};
export const extractSoldStatsOffer = response => {
  const includedData = response.provider;
  const listingsSoldCount =
    includedData?.attributes?.profile?.publicData?.listingsSoldCount || 0;
  const listingsSoldPrice =
    includedData?.attributes?.profile?.publicData?.listingsSoldPrice || 0;
  return { listingsSoldCount, listingsSoldPrice };
};

export const getTotalCount = (listingsSoldCount, newListingCount) => {
  return newListingCount ? listingsSoldCount + newListingCount : listingsSoldCount + 1 || 0;
};

export const getTotalPrice = (listingsSoldPrice, newPrice) => {
  return listingsSoldPrice + newPrice;
};

export const getTotalSoldPrice = (listingsSoldPrice, newPrice) => {
  // Convert the inputs to numbers
  listingsSoldPrice = parseFloat(listingsSoldPrice);
  newPrice = parseFloat(newPrice);
  return listingsSoldPrice + newPrice;
};

export const checkPriceLimit = (totalPrice, freePlanData, currentPlanData) => {
  const isExceedPriceLimit =
    (totalPrice >= FREE_PLAN_PRICE_LIMIT && freePlanData?.isFreePlanActive) ||
    (totalPrice >= MEMBER_PLAN_PRICE_LIMIT &&
      currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN);
  return isExceedPriceLimit;
};

export const checkCountLimit = (totalCount, freePlanData, currentPlanData) => {
  const isExceedCountLimit =
    (totalCount === FREE_PLAN_COUNT_LIMIT && freePlanData?.isFreePlanActive) ||
    (totalCount === MEMBER_PLAN_COUNT_LIMIT && currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN);
  return isExceedCountLimit;
};

export const filterCartItems = (cartItems, listingId) => {
  return cartItems && cartItems.filter(item => !listingId.includes(item.listingId));
};

const downloadFile = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFiles = (url, name) => {
  const pdfFileUrl = url;
  const pdfFileName = name;
  return downloadFile(pdfFileUrl, pdfFileName);
};


export const orderSaleTransitions = [
  transitions.REQUEST_PAYMENT,
  transitions.CONFIRM_PAYMENT,
  transitions.CONFIRM_PAYMENT_FREE_PLAN,
  transitions.EXPIRE_PAYMENT,
  transitions.AUTO_COMPLETE,
  transitions.MARK_DELIVERED,
  transitions.OFFER_ACCEPT_BY_PROVIDER,
  transitions.OFFER_ACCEPT_BY_PROVIDER_FREE_PLAN,
  transitions.CANCEL,
  transitions.EXPIRE_REVIEW_PERIOD,
  transitions.REVIEW_2_BY_CUSTOMER,
  transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
  transitions.REVIEW_1_BY_CUSTOMER,
  transitions.REVIEW_2_BY_PROVIDER,
  transitions.REVIEW_1_BY_PROVIDER,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL,
  transitions.ADMIN_VERIFICATION_DONE,
  transitions.ADMIN_VERIFICATION_CANCEL,
  transitions.DISPUTE,
  transitions.DISPUTE_VERIFICATION_DONE,
  transitions.DISPUTE_VERIFICATION_FAILED,
  transitions.ORDER_RETURNED_TO_PROVIDER,
  transitions.INITIATE_REFUND,
  transitions.AUTO_COMPLETE_AFTER_DISPUTE,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED_FREE_PLAN,
  transitions.CONFIRM_PAYMENT_FREE_PLAN,
  transitions.OPERATOR_MARK_DELIVERED,
  transitions.OPERATOR_MARK_DELIVERED_FREE_PLAN,
  transitions.AUTO_CANCEL_FREE_PLAN,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_AFTER_PAYOUT,
  transitions.AUTO_MARK_RECEIVED,
  transitions.AUTO_MARK_RECEIVED_FREE_PLAN,
  transitions.DISPUTE_FREE_PLAN,
  transitions.AUTO_COMPLETE_ORDER,
  transitions.ADMIN_VERIFICATION_DONE_AFTER_PAYOUT,
  transitions.PAYOUT_CREATED_AFTER_PRINT_LABEL,
  transitions.ADMIN_VERIFICATION_CANCEL_AFTER_PAYOUT,
  transitions.PAYOUT_CREATED_AFTER_DELIVERED,
  transitions.PAYOUT_CREATED_AFTER_DELIVERED_FREE_PLAN,
  transitions.AUTO_PAYOUT_AFTER_VERIFICATION,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT_FREE_PLAN,
  transitions.DISPUTED_VERIFICATION_DONE_FREE_PLAN,
  transitions.DISPUTED_VERIFICATION_FAILED_FREE_PLAN,
  transitions.AUTO_PAYOUT_CREATE,
  transitions.AUTO_PAYOUT_AFTER_DELIVERED,
  transitions.AUTO_MARK_RECEIVED_AFTER_PAYOUT,
  transitions.AUTO_MARK_RECEIVED_AFTER_PAYOUT_DELIVERED,
  transitions.DISPUTE_AFTER_PAYOUT,
  transitions.DISPUTE_AFTER_PAYOUT_DELIVERED,
  transitions.AUTO_CANCEL,
  transitions.BID_ACCEPTED_OPERATOR,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_1,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN_1,
  transitions.CANCEL_BUFFER_OVER,
  transitions.CANCEL_BUFFER_OVER_FREE_PLAN,
  transitions.CANCEL_CUSTOMER,
  transitions.CANCEL_CUSTOMER_FREE_PLAN,
];

export const supportedCurrency = [
  { label: "Bitcoin (BTC)", value: "BTC" },
  { label: "Ethereum (ETH)", value: "ETH" },
  { label: "Tether(ERC20) (USDT)", value: "USDT" },
  { label: "Tether(TRC20) (USDT_TRC20)", value: "USDT_TRC20" },
  { label: "USDCoin(ERC20) (USDC)", value: "USDC" }
];

export const commissionMap = {
  'business-enterprise': { percentage: 5 },
  'member-plan': { percentage: 8 },
};

// Function to calculate the commission amount
const calculateCommission = (amount, percentage) => (amount * percentage) / 100;

// Function to create a Money object
const createMoney = (amount, currency) => new Money(amount * 100, currency);

// Function to format money for display
const formatDisplayMoney = (intl, money) => formatMoney(intl, money);

// Main logic for calculating and formatting the amounts
export const processTransaction = (lineItems, currentPlanData, commissionMap, planName, intl) => {
  if (!lineItems || lineItems.length === 0) return {};

  const isDiscountedTransaction = lineItems.some(item => item.code === "line-item/discount");
  const providerCommissionPercentage = currentPlanData ? commissionMap[planName]?.percentage : 10;

  const unitPrice = lineItems[0].unitPrice.amount / 100;
  const stoadoFeeVAT = lineItems[3]?.lineTotal.amount / 100;
  const commissionAmount = calculateCommission(unitPrice, providerCommissionPercentage);
  const commissionPrice = createMoney(commissionAmount, 'GBP');
  const totalMakeAmount = unitPrice - commissionAmount + stoadoFeeVAT;
  const totalMake = createMoney(totalMakeAmount, 'GBP');

  const listingPrice = isDiscountedTransaction ? formatDisplayMoney(intl, lineItems[0].unitPrice) : null;
  const total = isDiscountedTransaction ? formatDisplayMoney(intl, totalMake) : null;
  const totalCommission = isDiscountedTransaction ? formatDisplayMoney(intl, commissionPrice) : null;

  return { listingPrice, total, totalCommission, stoadoFeeVAT };
};

export const PAYOUT_AFTER_2_DAYS = "Payout after 2 days of purchased";
export const PAYOUT_AFTER_5_DAYS = "Payout after 5 days of delivery";

export const pendingTransitions = [
  transitions.REQUEST_PAYMENT,
  transitions.CONFIRM_PAYMENT,
  transitions.CONFIRM_PAYMENT_FREE_PLAN,,
  transitions.MARK_DELIVERED,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL,
  transitions.ADMIN_VERIFICATION_DONE,
  transitions.ORDER_RETURNED_TO_PROVIDER,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED_FREE_PLAN,
  transitions.OPERATOR_MARK_DELIVERED,
  transitions.OPERATOR_MARK_DELIVERED_FREE_PLAN,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN,
  transitions.TRANSITION_PRINT_SHIPPING_LABEL_AFTER_PAYOUT,
  transitions.DISPUTE_FREE_PLAN,
  transitions.PAYOUT_CREATED_AFTER_DELIVERED_FREE_PLAN,
  transitions.AUTO_PAYOUT_AFTER_VERIFICATION,
  transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT_FREE_PLAN,
  transitions.DISPUTED_VERIFICATION_DONE_FREE_PLAN,
];