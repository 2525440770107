import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
// import {
//   fetchCurrentUserMessageNotifications,
//   fetchCurrentUserOfferRecieveNotifications,
// } from '../../ducks/user.duck';
import { orderSaleTransitions } from '../../util/dataExtractor';
import { getTransitionsNeedingProviderAttention } from '../../transactions/transaction';

// Notificaiton page size is max (100 items on page)
const NOTIFICATION_PAGE_SIZE = 100;

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_CURRENT_USER_ORDER_NOTIFICATIONS_SUCCESS =
  'app/InboxPage/FETCH_CURRENT_USER_ORDER_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_OFFER_RECIEVE_NOTIFICATIONS_SUCCESS =
  'app/InboxPage/FETCH_CURRENT_USER_OFFER_RECIEVE_NOTIFICATIONS_SUCCESS';
export const FETCH_CURRENT_USER_MESSAGE_NOTIFICATIONS_SUCCESS =
  'app/InboxPage/FETCH_CURRENT_USER_MESSAGE_NOTIFICATIONS_SUCCESS';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  currentUserOrderNotificationCount: 0,
  currentUserOrderMessageNotificationCount: 0,
  currentUserOfferRecieveNotificationCount: 0,
};

export default function inboxPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };
    case FETCH_CURRENT_USER_ORDER_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserOrderNotificationCount: payload?.transactions?.length };
    case FETCH_CURRENT_USER_MESSAGE_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserOrderMessageNotificationCount: payload.transactions.length };
    case FETCH_CURRENT_USER_OFFER_RECIEVE_NOTIFICATIONS_SUCCESS:
      return { ...state, currentUserOfferRecieveNotificationCount: payload.transactions.length };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});

export const fetchCurrentUserOfferRecieveNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_OFFER_RECIEVE_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

export const fetchCurrentUserOrderNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_ORDER_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});
export const fetchCurrentUserMessageNotificationsSuccess = transactions => ({
  type: FETCH_CURRENT_USER_MESSAGE_NOTIFICATIONS_SUCCESS,
  payload: { transactions },
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;

export const fetchCurrentUserOfferRecieveNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    only: 'sale',
    last_transitions: 'transition/make-an-offer-by-customer',
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
  };

  // dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      const transactions = response.data.data;
      dispatch(fetchCurrentUserOfferRecieveNotificationsSuccess(transactions));
    })
    .catch(e => console.log(e));
};

export const fetchCurrentUserMessageNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    last_transitions: 'transition/inquire-without-payment',
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
    include: ['listing', 'provider', 'provider.profileImage'],
  };

  // dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      let { currentUser } = getState().user;
      if (!currentUser || (currentUser && !currentUser.id)) {
        dispatch(sdk.currentUser.show()).then(res => {
          currentUser = res;
        });
      }



      const transactions = response.data.data;
      const filterNotification = transactions.find(
        e => e?.relationships?.provider?.data?.id?.uuid === currentUser?.id?.uuid
      )
        ? transactions.filter(e => e?.attributes?.metadata?.isCustomerMessage)
        : transactions.filter(e => e?.attributes?.metadata?.isProviderMessage);

      dispatch(fetchCurrentUserMessageNotificationsSuccess(filterNotification));
    })
    .catch(e => console.log(e));
};

export const fetchCurrentUserOrderNotifications = () => (dispatch, getState, sdk) => {
  const transitionsNeedingAttention = getTransitionsNeedingProviderAttention();
  if (transitionsNeedingAttention.length === 0) {
    // Don't update state, if there's no need to draw user's attention after last transitions.
    return;
  }

  const apiQueryParams = {
    only: 'order',
    last_transitions: [
      'transition/print-shipping-label',
      'transition/print-shipping-label-1',
      'transition/print-shipping-label-free-plan',
      'transition/print-shipping-label-free-plan-1',
      'transition/print-shipping-label-after-payout',
    ],
    page: 1,
    perPage: NOTIFICATION_PAGE_SIZE,
  };

  // dispatch(fetchCurrentUserNotificationsRequest());
  sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      let { currentUser } = getState().user;
      if (!currentUser || (currentUser && !currentUser.id)) {
        dispatch(sdk.currentUser.show()).then(res => {
          currentUser = res;
        });
      }


      const transactions = response.data.data;

      const filterNotification =
        transactions.filter(e => e?.attributes?.metadata?.isCustomerPurchase === true);



      dispatch(fetchCurrentUserOrderNotificationsSuccess(filterNotification));
    })
    .catch(e => console.log(e));
};

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab, type } = params;

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    offerRecieved: 'offerRecieved',
    offerSent: 'offerSent',
    bidRecieved: 'bidRecieved',
    bidSent: 'bidSent',
    messages: 'messages',
  };

  const onlyFilter = onlyFilterValues[tab];
  console.log(onlyFilter)
  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  }

  dispatch(fetchOrdersOrSalesRequest());

  const { page = 1 } = parse(search);

  const apiQueryParams = {
    only:
      tab === 'messages'
        ? null
        : tab === 'offerRecieved'
          ? 'sale'
          : tab === 'offerSent'
            ? 'order'
            : tab === 'bidRecieved'
              ? 'sale'
              : tab === 'bidSent'
                ? 'order'
                : onlyFilter,
    lastTransitions:
      tab === 'messages'
        ? ['transition/inquire-without-payment']
        : tab === 'offerRecieved' || tab === 'offerSent'
          ? [
            'transition/make-an-offer-by-customer',
            'transition/offer-decline-by-provider',
            'transition/expire-offer',
          ]
          : tab === 'bidRecieved' || tab === 'bidSent'
            ? [
              'transition/make-a-bid-by-customer',
            ]
            : orderSaleTransitions,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'protectedData',
      'metadata',
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName', 'profile.publicData'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      dispatch(fetchCurrentUserOfferRecieveNotifications(response));
      dispatch(fetchCurrentUserMessageNotifications(response));
      dispatch(fetchCurrentUserOrderNotifications(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchOrdersOrSalesError(storableError(e)));
      throw e;
    });
};
