/**
 * Transaction process graph for product orders:
 *   - default-purchase
 */

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes an order for a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  //Offer make by customer

  MAKE_AN_OFFER: 'transition/make-an-offer-by-customer',
  //Offer accept by provider
  OFFER_ACCEPT_BY_PROVIDER: 'transition/offer-accept-by-provider',
  OFFER_ACCEPT_BY_PROVIDER_FREE_PLAN: "transition/offer-accept-by-provider-free-plan",
  //Offer decline by provider
  OFFER_DECLINE_BY_PROVIDER: 'transition/offer-decline-by-provider',
  //expire-offer
  EXPIRE_OFFER: 'transition/expire-offer',

  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',
  OPERATOR_MARK_DELIVERED_AFTER_VERIFIED: "transition/operator-mark-delivered-after-verified",
  OPERATOR_MARK_DELIVERED_AFTER_VERIFIED_FREE_PLAN: "transition/operator-mark-delivered-after-verified-free-plan",

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  CONFIRM_PAYMENT_FREE_PLAN: "transition/confirm-payment-free-plan",

  CANCEL_BUFFER_OVER: "transition/cancel-buffer-over",
  CANCEL_BUFFER_OVER_FREE_PLAN: "transition/cancel-buffer-over-free-plan",

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  CANCEL_CUSTOMER: "transition/cancel-customer",
  CANCEL_CUSTOMER_FREE_PLAN: "transition/cancel-customer-free-plan",

  // Provider or opeartor can mark the product shipped/delivered
  MARK_DELIVERED: 'transition/mark-delivered',
  OPERATOR_MARK_DELIVERED: 'transition/operator-mark-delivered',
  OPERATOR_MARK_DELIVERED_FREE_PLAN: "transition/operator-mark-delivered-free-plan",
  ADMIN_VERIFICATION_DONE: 'transition/admin-verification-done',
  ADMIN_VERIFICATION_CANCEL: 'transition/admin-verification-cancel',
  // Customer can mark the product received (e.g. picked up from provider)
  MARK_RECEIVED_FROM_PURCHASED: 'transition/mark-received-from-purchased',
  AUTO_COMPLETE_AFTER_DISPUTE: "transition/auto-complete-after-dispute-cancel",

  // Automatic cancellation happens if none marks the delivery happened
  AUTO_CANCEL: 'transition/auto-cancel',
  AUTO_CANCEL_FREE_PLAN: "transition/auto-cancel-free-plan",

  AUTO_PAYOUT_CREATE: "transition/auto-payout-create",

  // Operator can cancel the purchase before product has been marked as delivered / received
  CANCEL: 'transition/cancel',
  TRANSITION_PRINT_SHIPPING_LABEL: "transition/print-shipping-label",
  TRANSITION_PRINT_SHIPPING_LABEL_1: "transition/print-shipping-label-1",
  TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN: "transition/print-shipping-label-free-plan",
  TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN_1: "transition/print-shipping-label-free-plan-1",
  TRANSITION_PRINT_SHIPPING_LABEL_AFTER_PAYOUT: "transition/print-shipping-label-after-payout",

  // If provider has marked the product delivered (e.g. shipped),
  // customer can then mark the product received
  MARK_RECEIVED: 'transition/mark-received',
  MARK_RECEIVED_BY_OPERATOR: "transition/mark-received-by-operator",

  // If customer doesn't mark the product received manually, it can happen automatically
  AUTO_MARK_RECEIVED: 'transition/auto-mark-received',
  AUTO_MARK_RECEIVED_FREE_PLAN: "transition/auto-mark-received-free-plan",
  AUTO_PAYOUT_AFTER_DELIVERED: "transition/auto-payout-after-delivered",

  // When provider has marked the product delivered, customer or operator can dispute the transaction
  DISPUTE: 'transition/dispute',
  DISPUTE_FREE_PLAN: "transition/dispute-free-plan",
  OPERATOR_DISPUTE: 'transition/operator-dispute',
  DISPUTE_VERIFICATION_DONE: "transition/dispute-verification-done",
  DISPUTE_VERIFICATION_FAILED: "transition/dispute-verification-failed",
  ORDER_RETURNED_TO_PROVIDER: "transition/order-returned-to-provider",
  RETURN_REJECTED: "transition/return-rejected",
  INITIATE_REFUND: "transition/initiate-refund-to-customer",
  // If nothing is done to disputed transaction it ends up to Canceled state
  AUTO_CANCEL_FROM_DISPUTED: 'transition/auto-cancel-from-disputed',

  // Operator can cancel disputed transaction manually
  CANCEL_FROM_DISPUTED: 'transition/cancel-from-disputed',

  // Operator can mark the disputed transaction as received
  MARK_RECEIVED_FROM_DISPUTED: 'transition/mark-received-from-disputed',

  // System moves transaction automatically from received state to complete state
  // This makes it possible to to add notifications to that single transition.
  AUTO_COMPLETE: 'transition/auto-complete',
  AUTO_COMPLETE_ORDER: "transition/auto-completed",

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',

  ADMIN_VERIFICATION_DONE_AFTER_PAYOUT: "transition/admin-verification-done-after-payout",
  PAYOUT_CREATED_AFTER_PRINT_LABEL: "transition/auto-payout-create-after-label",
  ADMIN_VERIFICATION_CANCEL_AFTER_PAYOUT: "transition/admin-verification-cancel-after-payout",
  PAYOUT_CREATED_AFTER_DELIVERED: "transition/operator-mark-delivered-after-payout",
  DISPUTE_AFTER_PAYOUT_DELIVERED: "transition/dispute-after-payout-delivered",
  PAYOUT_CREATED_AFTER_DELIVERED_FREE_PLAN: "transition/operator-mark-delivered-free-plan-after-payout",
  AUTO_PAYOUT_AFTER_VERIFICATION: "transition/auto-payout-create-after-verification",
  OPERATOR_MARK_DELIVERED_AFTER_PAYOUT: "transition/operator-mark-delivered-after-verified-payout",
  OPERATOR_MARK_DELIVERED_AFTER_PAYOUT_FREE_PLAN: "transition/operator-mark-delivered-after-verified-free-plan-payout",
  DISPUTED_VERIFICATION_DONE_FREE_PLAN: "transition/dispute-verification-done-free-plan",
  DISPUTED_VERIFICATION_FAILED_FREE_PLAN: "transition/dispute-verification-failed-free-plan",
  AUTO_MARK_RECEIVED_AFTER_PAYOUT: "transition/auto-mark-received-after-payout",
  DISPUTE_AFTER_PAYOUT: "transition/dispute-after-payout",
  AUTO_MARK_RECEIVED_AFTER_PAYOUT_DELIVERED: "transition/auto-mark-received-after-payout-delivered",

  //Transitions for Bid process
  MAKE_BID: "transition/make-a-bid-by-customer",
  BID_ACCEPTED_OPERATOR: "transition/bid-accepted",
  BID_DECLINED_OPERATOR: "transition/bid-declined",
  BID_EXPIRED: "transition/bid-expired",
  PAYMENT_COMPLETED: "transition/payment-completed",
  EXPIRE_PENDING_PAYMENT: "transition/expire-pending-payment",
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */

export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  OFFER: 'offer',
  OFFER_DECLINED: 'offer-declined',
  OFFER_EXPIRE: 'offer-expired',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PURCHASED: 'purchased',
  PURCHASED_CANCEL_AVAILABLE: 'purchased-cancel-available',
  PURCHASED_FREE_PLAN: "purchased-free-plan",
  PURCHASED_FREE_PLAN_CANCEL_AVAILABLE: "purchased-free-plan-cancel-available",
  DELIVERED: 'delivered',
  DELIVERED_FREE_PLAN: "delivered-free-plan",
  RECEIVED: 'received',
  DISPUTED: 'disputed',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  ORDER_COMPLETED: "order-completed",
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
  LABEL_PRINTED: "label-printed",
  ORDER_VERIFIED: "order-verified",
  RETURN_ORDER: "return-order",
  RETURN_CANCEL: "return-cancel",
  ITEM_RETURNED: "item-returned",
  PAYMENT_REFUND: "payment-refund",
  PAYOUT_CREATED: "payout-created",
  PAYOUT_CREATED_AFTER_LABEL: "payout-created-label-printed",
  PAYOUT_CREATED_AFTER_VERIFY: "payout-order-verified",
  DISPUTED_FREE_PLAN: "disputed-free-plan",
  PAYOUT_AFTER_DELIVERED: "payout-after-delivered",

  //Bid related states
  BID_SUBMITED: "bid-submited",
  BID_DECLINED: "bid-declined",
  REQUIRES_PAYMENT: "requires-payment",
  PAYMENT_EXPIRED_WARNING: "payment-expired-warning",
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'default-purchase/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
        [transitions.MAKE_AN_OFFER]: states.OFFER,
        [transitions.MAKE_BID]: states.BID_SUBMITED

      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },
    [states.OFFER]: {
      on: {
        [transitions.OFFER_ACCEPT_BY_PROVIDER]: states.REQUIRES_PAYMENT,
        [transitions.OFFER_ACCEPT_BY_PROVIDER_FREE_PLAN]: states.REQUIRES_PAYMENT,
        [transitions.OFFER_DECLINE_BY_PROVIDER]: states.OFFER_DECLINED,
        [transitions.EXPIRE_OFFER]: states.OFFER_EXPIRE,

      },
    },
    [states.BID_SUBMITED]: {
      on: {
        [transitions.BID_ACCEPTED_OPERATOR]: states.REQUIRES_PAYMENT,
        [transitions.BID_DECLINED_OPERATOR]: states.BID_DECLINED,
        [transitions.BID_EXPIRED]: states.BID_DECLINED,
      }
    },
    [states.REQUIRES_PAYMENT]: {
      on: {
        [transitions.PAYMENT_COMPLETED]: states.PURCHASED,
        [transitions.EXPIRE_PENDING_PAYMENT]: states.PAYMENT_EXPIRED_WARNING,
      }
    },
    [states.PAYMENT_EXPIRED_WARNING]: {},
    [states.BID_DECLINED]: {},
    [states.OFFER_EXPIRE]: {},
    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PURCHASED_CANCEL_AVAILABLE,
        [transitions.CONFIRM_PAYMENT_FREE_PLAN]: states.PURCHASED_FREE_PLAN_CANCEL_AVAILABLE
      },
    },

    [states.PURCHASED_CANCEL_AVAILABLE]: {
      on: {
        [transitions.CANCEL_BUFFER_OVER]: states.PURCHASED,
        [transitions.TRANSITION_PRINT_SHIPPING_LABEL_1]: states.LABEL_PRINTED,
        [transitions.CANCEL_CUSTOMER]: states.CANCELED
      },
    },

    [states.PURCHASED_FREE_PLAN_CANCEL_AVAILABLE]: {
      on: {
        [transitions.CANCEL_BUFFER_OVER_FREE_PLAN]: states.PURCHASED_FREE_PLAN,
        [transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN_1]: states.LABEL_PRINTED,
        [transitions.CANCEL_CUSTOMER_FREE_PLAN]: states.CANCELED
      },
    },

    [states.PURCHASED_FREE_PLAN]: {
      on: {
        // [transitions.MARK_RECEIVED_FROM_PURCHASED]: states.RECEIVED,
        [transitions.AUTO_CANCEL_FREE_PLAN]: states.CANCELED,
        // [transitions.CANCEL]: states.CANCELED,
        [transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN]: states.LABEL_PRINTED
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PURCHASED]: {
      on: {
        // [transitions.MARK_RECEIVED_FROM_PURCHASED]: states.RECEIVED,
        [transitions.AUTO_CANCEL]: states.CANCELED,
        [transitions.CANCEL]: states.CANCELED,
        [transitions.TRANSITION_PRINT_SHIPPING_LABEL]: states.LABEL_PRINTED,
        [transitions.AUTO_PAYOUT_CREATE]: states.PAYOUT_CREATED,
      },
    },

    [states.PAYOUT_CREATED]: {
      on: {
        [transitions.TRANSITION_PRINT_SHIPPING_LABEL_AFTER_PAYOUT]: states.LABEL_PRINTED
      },
    },

    [states.CANCELED]: {},
    [states.LABEL_PRINTED]: {
      on: {
        // [transitions.MARK_DELIVERED]: states.DELIVERED,
        [transitions.ADMIN_VERIFICATION_DONE]: states.ORDER_VERIFIED,
        [transitions.ADMIN_VERIFICATION_CANCEL]: states.CANCELED,
        [transitions.OPERATOR_MARK_DELIVERED]: states.DELIVERED,
        [transitions.OPERATOR_MARK_DELIVERED_FREE_PLAN]: states.DELIVERED_FREE_PLAN,
        [transitions.PAYOUT_CREATED_AFTER_PRINT_LABEL]: states.PAYOUT_CREATED_AFTER_LABEL
      },
    },

    [states.PAYOUT_CREATED_AFTER_LABEL]: {
      on: {

        [transitions.ADMIN_VERIFICATION_DONE_AFTER_PAYOUT]: states.ORDER_VERIFIED,
        [transitions.ADMIN_VERIFICATION_CANCEL_AFTER_PAYOUT]: states.CANCELED,
        [transitions.PAYOUT_CREATED_AFTER_DELIVERED]: states.DELIVERED,
        [transitions.PAYOUT_CREATED_AFTER_DELIVERED_FREE_PLAN]: states.DELIVERED_FREE_PLAN,
      },
    },

    [states.ORDER_VERIFIED]: {
      on: {

        [transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED]: states.DELIVERED,
        [transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED_FREE_PLAN]: states.DELIVERED_FREE_PLAN,
        [transitions.AUTO_PAYOUT_AFTER_VERIFICATION]: states.PAYOUT_CREATED_AFTER_VERIFY,
      },
    },
    [states.PAYOUT_CREATED_AFTER_VERIFY]: {
      on: {
        [transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT]: states.DELIVERED,
        [transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT_FREE_PLAN]: states.DELIVERED_FREE_PLAN
      },
    },


    [states.DELIVERED]: {
      on: {
        [transitions.AUTO_MARK_RECEIVED]: states.RECEIVED,
        [transitions.DISPUTE]: states.DISPUTED,
        [transitions.AUTO_PAYOUT_AFTER_DELIVERED]: states.PAYOUT_AFTER_DELIVERED,
      },
    },

    [states.PAYOUT_AFTER_DELIVERED]: {
      on: {
        [transitions.AUTO_MARK_RECEIVED_AFTER_PAYOUT]: states.RECEIVED,
        [transitions.DISPUTE_AFTER_PAYOUT]: states.DISPUTED,
        [transitions.DISPUTE_AFTER_PAYOUT_DELIVERED]: states.DISPUTED
      },
    },

    [states.DELIVERED_FREE_PLAN]: {
      on: {
        [transitions.AUTO_MARK_RECEIVED_FREE_PLAN]: states.RECEIVED,
        [transitions.DISPUTE_FREE_PLAN]: states.DISPUTED_FREE_PLAN,
      },
    },

    [states.DISPUTED_FREE_PLAN]: {
      on: {
        [transitions.DISPUTED_VERIFICATION_DONE_FREE_PLAN]: states.RETURN_ORDER,
        [transitions.DISPUTED_VERIFICATION_FAILED_FREE_PLAN]: states.RETURN_CANCEL,
      },
    },



    [states.DISPUTED]: {
      on: {
        [transitions.DISPUTE_VERIFICATION_DONE]: states.RETURN_ORDER,
        [transitions.DISPUTE_VERIFICATION_FAILED]: states.RETURN_CANCEL,
      },
    },

    [states.RETURN_ORDER]: {
      on: {
        [transitions.ORDER_RETURNED_TO_PROVIDER]: states.ITEM_RETURNED,
      },
    },

    [states.RETURN_CANCEL]: {
      on: {
        [transitions.AUTO_COMPLETE_AFTER_DISPUTE]: states.COMPLETED,
      },
    },

    [states.ITEM_RETURNED]: {
      on: {
        [transitions.INITIATE_REFUND]: states.PAYMENT_REFUND,
        [transitions.RETURN_REJECTED]:states.COMPLETED
      },
    },
    [states.PAYMENT_REFUND]: {},
    [states.RECEIVED]: {
      on: {
        [transitions.AUTO_COMPLETE]: states.COMPLETED,
      },
    },

    [states.COMPLETED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.CONFIRM_PAYMENT,
    transitions.AUTO_CANCEL,
    transitions.CANCEL,
    transitions.MARK_DELIVERED,
    transitions.OPERATOR_MARK_DELIVERED,
    transitions.DISPUTE,
    transitions.OPERATOR_DISPUTE,
    transitions.DISPUTE_VERIFICATION_DONE,
    transitions.DISPUTE_VERIFICATION_FAILED,
    transitions.ORDER_RETURNED_TO_PROVIDER,
    transitions.AUTO_COMPLETE,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.TRANSITION_PRINT_SHIPPING_LABEL,
    transitions.ADMIN_VERIFICATION_DONE,
    transitions.ADMIN_VERIFICATION_CANCEL,
    transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED,
    transitions.INITIATE_REFUND,
    transitions.AUTO_MARK_RECEIVED_FREE_PLAN,
    transitions.DISPUTE_FREE_PLAN,
    transitions.AUTO_COMPLETE_ORDER,
    transitions.ADMIN_VERIFICATION_DONE_AFTER_PAYOUT,
    // transitions.PAYOUT_CREATED_AFTER_PRINT_LABEL,
    transitions.ADMIN_VERIFICATION_CANCEL_AFTER_PAYOUT,
    transitions.PAYOUT_CREATED_AFTER_DELIVERED,
    transitions.PAYOUT_CREATED_AFTER_DELIVERED_FREE_PLAN,
    // transitions.AUTO_PAYOUT_AFTER_VERIFICATION,
    transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT,
    transitions.OPERATOR_MARK_DELIVERED_AFTER_PAYOUT_FREE_PLAN,
    transitions.DISPUTED_VERIFICATION_DONE_FREE_PLAN,
    transitions.DISPUTED_VERIFICATION_FAILED_FREE_PLAN,
    transitions.DISPUTE_AFTER_PAYOUT_DELIVERED,
    transitions.AUTO_COMPLETE_AFTER_DISPUTE,
    transitions.OPERATOR_MARK_DELIVERED_AFTER_VERIFIED_FREE_PLAN,
    transitions.CONFIRM_PAYMENT_FREE_PLAN,
    transitions.OPERATOR_MARK_DELIVERED_FREE_PLAN,
    transitions.AUTO_CANCEL_FREE_PLAN,
    transitions.TRANSITION_PRINT_SHIPPING_LABEL_FREE_PLAN,
    transitions.TRANSITION_PRINT_SHIPPING_LABEL_AFTER_PAYOUT,
    transitions.AUTO_MARK_RECEIVED,
  ].includes(transition);
};
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [transitions.REQUEST_PAYMENT, transitions.REQUEST_PAYMENT_AFTER_INQUIRY, transitions.PAYMENT_COMPLETED].includes(
    transition
  );
};

// Check when transaction is completed (item is received and review notifications sent)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.AUTO_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
    transitions.AUTO_COMPLETE_AFTER_DISPUTE
  ];
  return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (order did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.CANCEL,
    transitions.AUTO_CANCEL,
    transitions.AUTO_CANCEL_FROM_DISPUTED,
    transitions.CANCEL_FROM_DISPUTED,
    transitions.INITIATE_REFUND,
    transitions.ADMIN_VERIFICATION_CANCEL,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PURCHASED];
