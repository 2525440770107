import React from 'react'
import css from './ViewCount.module.css'

const ViewCount = props => {
    const { viewCount = 0 } = props
    return null
    return <div className={css.viewCount}>
        {viewCount} views
    </div>
}

export default ViewCount