import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import {
  FREE_PLAN_PRICE_LIMIT,
  MEMBER_PLAN_PRICE_LIMIT,
  SUBSCRIPTION_MEMBER_PLAN,
  propTypes,
} from '../../util/types';
import { ensureListing, ensureUser } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import css from './ListingCard.module.css';
import { checkCountLimit, checkPriceLimit, getLabel } from '../../util/dataExtractor';
import { listingColors, listingConditions } from '../../config/configListing';
import { listingBrands } from '../../config/configBrand';
import { listingFieldTypes } from '../../config/configTypes';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { wishlistListingSuccess } from '../../containers/MyWishlistPage/MyWishlistPage.duck';
import { types as sdkTypes } from '../../util/sdkLoader';
import IconCollection from '../IconCollection/IconCollection';

const { UUID } = sdkTypes;

const priceData = (price, currency, intl) => {
  // Assuming formatMoney is a function that formats the price correctly
  const formatMoney = (intl, amount) => {
    return new Intl.NumberFormat(intl.locale, {
      style: 'currency',
      currency: currency,
    }).format(amount);
  };

  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price.amount / 100);
    return { formattedPrice, priceTitle: formattedPrice };
  }

  if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }

  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const dispatch = useDispatch();
  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const state = useSelector(state => state);
  const { currentUser } = state.user;
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    isWhishlist,
    isProfile,
    isDiscoverCard,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const stockQuantity = currentListing?.currentStock?.attributes?.quantity;

  console.log({ id, stockQuantity });
  const { listingType } = publicData || {};
  const { color, condition, size, brand, type } = publicData || {};
  const slug = createSlug(title || '');
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isAuction = listingType == 'auction-product';
  const bidPriceMaybe = isAuction && publicData.currentBid ? publicData.currentBid : price;
  const listingPrice = bidPriceMaybe ? bidPriceMaybe : listing?.attributes?.price;

  const { listingsSoldPrice, currentPlanData, freePlanData, listingsSoldCount } =
    (!!author?.id && author.attributes.profile.publicData) || {};

  const totalLimit = freePlanData?.isFreePlanActive
    ? FREE_PLAN_PRICE_LIMIT
    : currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN
    ? MEMBER_PLAN_PRICE_LIMIT
    : null;


    

  const isFreePlanActive = freePlanData?.isFreePlanActive;
  const isSubscriptionMember = currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN;
  const remainingLimit = totalLimit - listingsSoldPrice;
  const itemPrice = bidPriceMaybe?.amount / 100;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith('scaled-medium'))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;
  const { wishListItems = [] } =
    (!!currentUser?.id && currentUser.attributes.profile.protectedData) || {};

  const index = wishListItems && wishListItems.findIndex(e => e == currentListing.id.uuid);

  const handleFavouriteItems = async () => {
    try {
      if (!currentUser) {
        const state = { from: `${location.pathname}${location.search}${location.hash}` };
        history.push(createResourceLocatorString('SignupPage', routeConfiguration, {}, {}), state);
      } else {
        if (index > -1) {
          console.log('wishListItems', wishListItems);
          wishListItems.splice(index, 1);
          // console.log('wishListItems.splice(index, 1);', wishListItems.splice(index, 1));

          const addedwishListItems = Array.from(new Set(wishListItems));
          console.log('addedwishListItems', addedwishListItems);
          // console.log('currentListing.id.uuid', currentListing.id.uuid);
          const type = addedwishListItems.includes(currentListing.id.uuid) ? 'added' : 'removed';
          const profile = {
            protectedData: {
              wishListItems: addedwishListItems,
            },
          };
          const wishlist = {
            currentListingId: currentListing.id.uuid,
            type: type,
          };
          const result = await dispatch(updateProfile(profile, wishlist));
          if (result) {
            const modifiedId = addedwishListItems?.map(elm => new UUID(elm));

            dispatch(wishlistListingSuccess(modifiedId));
            dispatch(fetchCurrentUser());
          }
        } else {
          console.log('in else');
          wishListItems.push(currentListing.id.uuid);
          const addedwishListItems = Array.from(new Set(wishListItems));
          const type = addedwishListItems.includes(currentListing.id.uuid) ? 'added' : 'removed';

          const profile = {
            protectedData: {
              wishListItems: addedwishListItems,
            },
          };
          const wishlist = {
            currentListingId: currentListing.id.uuid,
            type: type,
          };

          const result = await dispatch(updateProfile(profile, wishlist));
          if (result) {
            dispatch(fetchCurrentUser());
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Check if stockQuantity exists and is greater than zero
  if (isWhishlist && stockQuantity === 0) {
    // If stockQuantity does not exist or is zero, return null to prevent rendering
    return null;
  }

  const handleSubmit = values => {
    const { listingPrice } = values;
    const initialValues = {
      listing,
      orderData: {
        listingPrice,
        deliveryMethod: 'shipping',
      },
      confirmPaymentError: null,
    };
    const saveToSessionStorage = !currentUser;

    // Customize checkout page state with current listing and selected orderData
    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routeConfiguration);

    dispatch(setInitialValues(initialValues, saveToSessionStorage));
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routeConfiguration,
        { id: listing?.id?.uuid, slug: createSlug(currentListing?.attributes?.title) },
        {}
      )
    );
  };
  const isExceedPriceLimit = checkPriceLimit(listingsSoldPrice, freePlanData, currentPlanData);
  const isExceedCountLimit = checkCountLimit(listingsSoldCount, freePlanData, currentPlanData);
  console.log({
    isExceedCountLimit,
    totalLimit: totalLimit,
    remainingLimit: remainingLimit,
    itemPrice,
    id: listing?.id?.uuid,
    isExceedPriceLimit,
  });
  console.log({
    isFreePlanActive,
    isSubscriptionMember,
    totalLimit,
    remainingLimit:remainingLimit < itemPrice,
    isActive: !currentPlanData?.isActive,
    isFreePlanActive:!freePlanData?.isFreePlanActive,
    isExceedPriceLimit,
    isExceedCountLimit
    }
    )
  const buyNowBttn =
    ((isFreePlanActive || isSubscriptionMember) && totalLimit && remainingLimit < itemPrice) ||
    (!currentPlanData?.isActive && !freePlanData?.isFreePlanActive) ||
    ((isExceedPriceLimit || isExceedCountLimit) &&
      (currentPlanData?.isActive || freePlanData?.isFreePlanActive)) ? (
      <button className={css.buyNowBtn}>
        <FormattedMessage id="ListingCard.viewOnly" />
      </button>
    ) : (
      <button
        className={css.buyNowBtn}
        onClick={e => {
          if (!isAuction) {
            e.preventDefault();
            handleSubmit({ listingPrice });
          }
        }}
      >
        <FormattedMessage id={isAuction ? 'ListingCard.bidNow' : 'ListingCard.buyNow'} />
      </button>
    );

  return (
    <>
      {isWhishlist ? (
        <div className={classes}>
          <NamedLink name="ListingPage" params={{ id, slug }} className={css.link}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
                isListingCard={true}
                handleFavouriteItems={handleFavouriteItems}
                index={index}
                stockQuantity={stockQuantity}
              />
            </AspectRatioWrapper>
            <div className={css.info}>
              {/* {stockQuantity ===0?<div className={css.soldOutText}>Sold Out</div>:null} */}
              <div className={css.title}>
                {title?.length > 25 ? title?.slice(0, 25) + '…' : title}
              </div>
              <div className={css.category}>
                <span>{condition && getLabel(listingConditions, condition)}</span>{' '}
                <span>{color && getLabel(listingColors, color)}</span>{' '}
                <span>
                  {brand
                    ? getLabel(listingBrands, brand)
                    : type
                    ? getLabel(listingFieldTypes, type)
                    : null}
                </span>
              </div>
              <div className={css.cardFooter}>
                <PriceMaybe
                  className={css.price}
                  price={bidPriceMaybe}
                  publicData={publicData}
                  config={config}
                  intl={intl}
                />
                {buyNowBttn}
              </div>
            </div>
          </NamedLink>
        </div>
      ) : isProfile ? (
        <div className={!stockQuantity ? css.notClickable : classes}>
          {!stockQuantity && <div className={css.stockQuantity}></div>}
          <NamedLink name="ListingPage" className={css.link} params={{ id, slug }}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
                isListingCard={true}
                handleFavouriteItems={handleFavouriteItems}
                index={index}
                stockQuantity={stockQuantity}
              />
            </AspectRatioWrapper>
            <div className={css.info}>
              {/* {!stockQuantity ? <div className={css.soldOutText}>Sold Out</div> : null} */}
              <div className={css.title}>
                {title.length > 25 ? title.slice(0, 25) + '…' : title}
              </div>
              <div className={css.category}>
                <span>{condition && getLabel(listingConditions, condition)}</span>{' '}
                <span>{color && getLabel(listingColors, color)}</span>{' '}
                <span>
                  {brand
                    ? getLabel(listingBrands, brand)
                    : type
                    ? getLabel(listingFieldTypes, type)
                    : null}
                </span>
              </div>
              <div className={css.cardFooter}>
                {!stockQuantity ? null : (
                  <PriceMaybe
                    className={css.price}
                    price={bidPriceMaybe}
                    publicData={publicData}
                    config={config}
                    intl={intl}
                  />
                )}
                {((isFreePlanActive || isSubscriptionMember) &&
                  totalLimit &&
                  remainingLimit < itemPrice) ||
                (!currentPlanData?.isActive && !freePlanData?.isFreePlanActive) ||
                ((isExceedPriceLimit || isExceedCountLimit) &&
                  (currentPlanData?.isActive || freePlanData?.isFreePlanActive)) ? (
                  <button className={!stockQuantity ? css.seeSimilarBtn : css.buyNowBtn}>
                    {!stockQuantity ? (
                      <FormattedMessage id="ListingCard.soldOut" />
                    ) : (
                      <FormattedMessage id="ListingCard.viewOnly" />
                    )}
                  </button>
                ) : (
                  <button
                    className={!stockQuantity ? css.seeSimilarBtn : css.buyNowBtn}
                    onClick={e => {
                      if (!isAuction) {
                        e.preventDefault();
                        stockQuantity && handleSubmit({ listingPrice });
                      }
                    }}
                  >
                    {!stockQuantity ? (
                      <FormattedMessage id="ListingCard.soldOut" />
                    ) : (
                      <FormattedMessage
                        id={isAuction ? 'ListingCard.bidNow' : 'ListingCard.buyNow'}
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
          </NamedLink>
        </div>
      ) : (
        <div className={css.cardRoot}>
          <NamedLink name="ListingPage" params={{ id, slug }} className={css.link}>
            {(isProfile ? stockQuantity : true) && stockQuantity !== 0 && (
              <span
                className={classNames(css.wishlistIcon, index > -1 && css.itemWishlisted)}
                onClick={e => {
                  e.preventDefault();
                  handleFavouriteItems();
                }}
              >
                <IconCollection icon="heart" />
              </span>
            )}
            <AspectRatioWrapper
              className={classNames(
                css.aspectRatioWrapper,
                isDiscoverCard && css.discoverCardImage
              )}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
                isListingCard={true}
                handleFavouriteItems={handleFavouriteItems}
                index={index}
                stockQuantity={stockQuantity}
              />
            </AspectRatioWrapper>
            <div className={classNames(css.info, isDiscoverCard && css.discoverListingCard)}>
              <div className={classNames(isDiscoverCard && css.titleWrapper)}>
                <div className={css.title}>
                  {/* {title?.length > 25 ? title?.slice(0, 25) + '…' : title} */}
                  {title}
                </div>
                {isDiscoverCard && (
                  <div className={css.category}>
                    <span>{condition && getLabel(listingConditions, condition)}</span>{' '}
                    {/* <span>{color && getLabel(listingColors, color)}</span>{' '} */}
                    <span>
                      {brand
                        ? getLabel(listingBrands, brand)
                        : type
                        ? getLabel(listingFieldTypes, type)
                        : null}
                    </span>
                  </div>
                )}
              </div>
              <div className={classNames(css.cardFooter, isDiscoverCard && css.discoverCardFooter)}>
                <PriceMaybe
                  className={css.price}
                  price={bidPriceMaybe}
                  publicData={publicData}
                  config={config}
                  intl={intl}
                />
                {buyNowBttn}
              </div>
            </div>
          </NamedLink>
        </div>
      )}
    </>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
